
import { useEffect, useState, useRef, RefObject } from 'react'
import { navFlexTestRefs } from 'tests/navFlexTestUtils'

type UseNavFlexProps = {
  navReset: boolean;
  forceDesktop?: boolean;
} & navFlexTestRefs;


export const useNavFlex = (props: UseNavFlexProps) => {
  const { 
    navReset, 
    forceDesktop = false, 
    ...testRefs
  } = props

  const defaultNavRef = useRef<HTMLElement>(null)
  const defaultHeaderRef = useRef<HTMLDivElement>(null)
  const defaultUserRef = useRef<HTMLLIElement>(null)
  const defaultLogoRef = useRef<HTMLImageElement>(null)
  const defaultWfrRef = useRef<HTMLDivElement>(null)
  const defaultCtaRef = useRef<HTMLButtonElement>(null)
  const defaultCartRef = useRef<HTMLLIElement>(null)

  const navRef = testRefs.navRef || defaultNavRef;
  const headerRef = testRefs.headerRef || defaultHeaderRef;
  const userRef = testRefs.userRef || defaultUserRef;
  const logoRef = testRefs.logoRef || defaultLogoRef;
  const wfrRef = testRefs.wfrRef || defaultWfrRef;
  const ctaRef = testRefs.ctaRef || defaultCtaRef;
  const cartRef = testRefs.cartRef || defaultCartRef;

  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [hideCTA, setHideCTA] = useState<boolean>(false)
  const [hideLogo, setHideLogo] = useState<boolean>(false)
  const [navIsLoading, setNavIsLoading] = useState<boolean>(true)
  const [userWidth, setUserWidth] = useState<number>(0)
  const [navWidth, setNavWidth] = useState<number>(0)
  const [logoWidth, setLogoWidth] = useState<number>(0)
  const [headerWidth, setHeaderWidth] = useState<number>(0)
  const [wfrWidth, setWfrWidth] = useState<number>(0)
  const [ctaWidth, setCtaWidth] = useState<number>(0)
  const [cartWidth, setCartWidth] = useState<number>(0)

  useEffect(() => {
    const updateNavOnInit = () =>
      setNavWidth((prevWidth) => {
        const currentWidth = navRef.current?.offsetWidth
        if (currentWidth && !prevWidth) {
          return currentWidth
        }
        return prevWidth
      })

    const navFlexHandler = () => {
      setHeaderWidth((prev) => headerRef.current?.offsetWidth || prev)
      setLogoWidth((prev) => logoRef.current?.offsetWidth || prev)
      setWfrWidth((prev) => wfrRef.current?.offsetWidth || prev)
      setCartWidth((prev) => cartRef.current?.offsetWidth || prev)
      setUserWidth((prev) => userRef.current?.offsetWidth || prev)
      setCtaWidth((prev) => ctaRef.current?.offsetWidth || prev)
      updateNavOnInit()
      
      const availableSpaceOnDesktop = headerWidth - logoWidth - userWidth - navWidth - wfrWidth - ctaWidth - cartWidth
      const availableSpaceOnMobile = headerWidth - logoWidth - userWidth - ctaWidth - cartWidth

      if (!navWidth && !headerWidth && !userWidth && !logoWidth) return

      if (availableSpaceOnMobile < 135) {
        setIsMobile(true)
        setHideLogo(true)
        setHideCTA(true)        
      } else if (availableSpaceOnDesktop < 90) {
        setIsMobile(true)
        setHideLogo(true)
        setHideCTA(false)        
      } else if (availableSpaceOnDesktop < 200) {
        setIsMobile(false)
        setHideLogo(true)
        setHideCTA(false)
      } else if (availableSpaceOnDesktop >= 200) {
        setIsMobile(false)
        setHideLogo(false)
        setHideCTA(false)
      }

      setNavIsLoading(false)
    }

    navFlexHandler()

    window.addEventListener('resize', navFlexHandler)

    return () => {
      window.removeEventListener('resize', navFlexHandler)
    }
  }, [
    userWidth,
    navWidth,
    headerWidth,
    logoWidth,
    logoRef,
    userRef,
    navRef,
    headerRef,
    navReset,
    wfrWidth,
    wfrRef,
    ctaRef,
    ctaWidth,
    cartWidth,
    cartRef,
  ])

  if (forceDesktop) return { isMobile: false, navIsLoading: false }

  return {
    isMobile,
    navIsLoading,
    hideCTA,
    hideLogo,
    navRef,
    logoRef,
    wfrRef,
    headerRef,
    userRef,
    ctaRef,
    cartRef,
  }
}
