import { MealPlanDetail } from './MealPlan'

export type Address = {
  addressId?: string
  street: string
  city: string
  country: string
  state: string
  zipCode: string
  apartment: string
}

export type PriceBreakdown = {
  total: number
  subtotal: number
  discount: number
  taxes: number
  shipping: number
}

export type PaymentDetails = {
  priceBreakdown: PriceBreakdown
  externalId: string
  cardLast4Digits?: string
  paymentDate: string
  status: PaymentStatus
}

export enum Carriers {
  UPS = 'ups',
  FEDEX = 'fedex',
}

export enum OrderType {
  MEAL_PLAN = 'MEAL_PLAN',
  TRIAL_BOX = 'TRIAL_BOX',
  PRODUCTS_SUBSCRIPTION = 'PRODUCTS_SUBSCRIPTION',
  ONE_TIME = 'ONE_TIME',
}

export enum OrderStatus {
  CREATED = 'CREATED',
  PREPARING_SHIPMENT = 'PREPARING_SHIPMENT',
  SHIPPED = 'SHIPPED',
  DELIVERED = 'DELIVERED',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
}

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  NON_RENEWING = 'NON_RENEWING',
}

export enum PaymentStatus {
  FAILED = 'FAILED',
  PAID = 'PAID',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED',
}

export type OrderHistory = {
  orderId: string
  type: OrderType
  price: number
  paymentStatus: PaymentStatus
  paymentDate: string
  pet: string
}

export type OrderEvent = BaseOrderEvent | OrderPeparingShipmentEvent | OrderShippedEvent

export type BaseOrderEvent = {
  status: OrderStatus
  occurredAt: string
}

export type OrderPeparingShipmentEvent = BaseOrderEvent & {
  status: OrderStatus.PREPARING_SHIPMENT
  estimatedShipDate: string
}

export const isPreparingShipmentOrderEvent = (
  orderEvent: any
): orderEvent is OrderPeparingShipmentEvent => {
  return isStatusEvent<OrderPeparingShipmentEvent>(orderEvent, OrderStatus.PREPARING_SHIPMENT)
}

export type OrderShippedEvent = BaseOrderEvent & {
  status: OrderStatus.SHIPPED
  trackingNumber: string
  carrier: Carriers
  estimatedDeliveryDate: string
  trackingLink: string
}

export const isStatusEvent = <T>(orderEvent: any, status: OrderStatus): orderEvent is T => {
  return orderEvent.status === status
}

export const isShippedOrderEvent = (orderEvent: any): orderEvent is OrderShippedEvent => {
  return isStatusEvent<OrderShippedEvent>(orderEvent, OrderStatus.SHIPPED)
}

export type Order = {
  orderId: string
  paymentDetails: PaymentDetails
  createdAt: string
  type: OrderType
  historic: {
    events: OrderEvent[]
  }
  shippingAddress: Address
  status: OrderStatus
}

export type OrderDetail = MealPlanOrderDetail | ProductsSubscriptionOrderDetail | OneTimeOrderDetail

export type MealPlanOrderDetail = Order & {
  frequency: number
  petName: string
  mealPlan: MealPlanDetail
  products?: {
    name: string
    quantity: number
    price: number
    weight: number
    productId: string
  }[]
}

export type ProductsSubscriptionOrderDetail = Order & {
  frequency: number
  products: {
    name: string
    quantity: number
    price: number
    weight: number
    productId: string
  }[]
}

export type OneTimeOrderDetail = Order & {
  products: {
    name: string
    quantity: number
    price: number
    weight: number
    productId: string
  }[]
}

export const isMealPlanOrderDetail = (
  orderDetail: OrderDetail
): orderDetail is MealPlanOrderDetail =>
  orderDetail.type === OrderType.MEAL_PLAN || orderDetail.type === OrderType.TRIAL_BOX

export const isProductsSubscriptionOrderDetail = (
  orderDetail: OrderDetail
): orderDetail is ProductsSubscriptionOrderDetail =>
  orderDetail.type === OrderType.PRODUCTS_SUBSCRIPTION

export const isOneTimeOrderDetail = (orderDetail: OrderDetail): orderDetail is OneTimeOrderDetail =>
  orderDetail.type === OrderType.ONE_TIME

export enum SubscriptionTypeEnum {
  MEAL_PLAN = 'MEAL_PLAN',
  BULK_PRODUCTS = 'BULK_PRODUCTS',
}

export type SubscriptionType = {
  nextOrderCreationDate: string
  subscription?: {
    subscriptionId: string
    externalId: string
    type: 'MEAL_PLAN' | 'BULK_PRODUCTS'
    status: SubscriptionStatus
    paymentDetails: PaymentDetails
    clientId: string
    clientFullName: string
    lastOrderCreationDate: string
    nextOrderCreationDate: string
    frequency: number
    notes: string
  }
}

export type SubscriptionPauseType = {
  subscriptionId: string
  externalId: string
  type: 'MEAL_PLAN' | 'BULK_PRODUCTS'
  status: SubscriptionStatus
  paymentDetails: PaymentDetails
  clientId: string
  clientFullName: string
  lastOrderCreationDate: string
  nextOrderCreationDate: string
  frequency: number
  notes: string
  historic?: any
  tags?: any
}

export enum ProteinName {
  BEEF = 'BEEF',
  CHICKEN = 'CHICKEN',
  DUCK = 'DUCK',
  LAMB = 'LAMB',
  TURKEY = 'TURKEY',
  VENISON = 'VENISON',
}
