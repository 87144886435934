import React, { ReactNode, useState } from 'react'
import { useRouter } from 'hooks/useRouter'
import Image from 'next/image'
import { Trigger, Root } from '@radix-ui/react-dialog'
import { VisuallyHidden } from 'components/VisuallyHidden'
import { useTranslation } from 'hooks/useTranslation'
import { Icon } from 'components/Icon'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { MyAccountLink, ShoppingCartLink } from 'components/Header/Header'
import { VerticalSeparator } from 'components/VerticalSeparator'
import { IconName } from 'components/Icon/iconNames'
import { Content, CloseButton, SectionsNav, SocialNav, SubMenu } from './MobileHeaderMenu.styles'
import { Link } from 'components/Link'
import { TranslationKeys } from 'locales/translationKeys'
import { IconWrapper } from 'components/Header/Header.styles'

export type MobileHeaderMenuProps = {
  isMyAccountMenuOpen: boolean
  setIsMyAccountMenuOpen: (isMyAccountMenuOpen: boolean) => void
  setShowLogoutConfirmationModal: (isShowLogoutConfirmationModal: boolean) => void
  isLogged: boolean
  trigger?: ReactNode
  defaultOpen?: boolean
  open?: boolean
  onOpenChange?: (isOpen: boolean) => void
}

type SocialNavType = {
  label: string
  icon: IconName
  href: string
}
export const MobileHeaderMenu: React.FC<MobileHeaderMenuProps> = ({
  isMyAccountMenuOpen,
  setIsMyAccountMenuOpen,
  setShowLogoutConfirmationModal,
  isLogged,
  open,
  trigger,
  onOpenChange,
  defaultOpen = false,
  ...props
}) => {
  const { t } = useTranslation()

  const { pathname } = useRouter()

  const [isMyAccountMobileMenuOpen, setIsMyAccountMobileMenuOpen] = useState(isMyAccountMenuOpen)

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)

  type subsection = {
    readonly label: TranslationKeys
    readonly url: string
  }

  const RECIPES_NAV: subsection[] = [
    { label: 'header:recipes.beef', url: '/recipes/beef' },
    { label: 'header:recipes.chicken', url: '/recipes/chicken' },
    { label: 'header:recipes.lamb', url: '/recipes/lamb' },
    { label: 'header:recipes.duck', url: '/recipes/duck' },
    { label: 'header:recipes.turkey', url: '/recipes/turkey' },
    { label: 'header:recipes.venison', url: '/recipes/venison' },
  ]

  const SECTIONS_NAV = [
    {
      label: t('header:CTA'),
      href: '/meal-plan',
    },
    {
      label: t('common:mobile-menu.wwfr'),
      href: '/why-we-feed-raw',
    },
    {
      label: t('common:mobile-menu.ourStory'),
      href: '/our-story',
    },
    {
      label: t('header:recipes.main'),
      href: '/recipes',
      subsections: RECIPES_NAV,
    },
    {
      label: t('common:mobile-menu.products'),
      href: '/products',
    },
    {
      label: t('header:reviews'),
      href: '/reviews',
    },
  ]

  const SOCIAL_NAV: SocialNavType[] = [
    {
      label: t('common:mobile-menu.instagram'),
      icon: 'instagram',
      href: 'https://www.instagram.com/wefeedraw/',
    },
    {
      label: t('common:mobile-menu.facebook'),
      icon: 'facebook',
      href: 'https://www.facebook.com/wefeedraw/',
    },
    {
      label: t('common:mobile-menu.email'),
      icon: 'envelope',
      href: 'mailto:hello@wefeedraw.com',
    },
    {
      label: t('common:mobile-menu.phone'),
      icon: 'phone',
      href: 'tel:+18437322849',
    },
  ]

  return (
    <Root defaultOpen={defaultOpen} open={open} onOpenChange={onOpenChange}>
      <Trigger asChild>{trigger}</Trigger>
      <Content {...props}>
        <Stack direction="column" justifyContent="space-between" css={{ height: '100%' }}>
          <div>
            <Stack justifyContent="space-between">
              <Stack alignItems="center">
                <Link href="/" passHref>
                  <a aria-label="We Feed Raw">
                    <Image
                      src="/assets/company-name-white.svg"
                      alt=""
                      width="136px"
                      height="12px"
                    />
                  </a>
                </Link>

                <CloseButton>
                  <Icon name="close-line" />
                  <VisuallyHidden>{t('common:close')}</VisuallyHidden>
                </CloseButton>
              </Stack>

              <Stack alignItems="center" gap="3">
                <MyAccountLink
                  isMobileScreenMenu
                  isLogged={isLogged}
                  isMyAccountMenuOpen={isMyAccountMobileMenuOpen}
                  setIsMyAccountMenuOpen={setIsMyAccountMobileMenuOpen}
                  setShowLogoutConfirmationModal={setShowLogoutConfirmationModal}
                />

                <VerticalSeparator size="5" color="accent-white" />

                <ShoppingCartLink />
              </Stack>
            </Stack>
          </div>

          <Stack direction="column" justifyContent="space-between" css={{ height: '100%' }}>
            <SectionsNav>
              <Stack as="ul" direction="column" gap="3">
                {SECTIONS_NAV.map(({ label, href, subsections }) => (
                  <li key={href}>
                    {href === pathname ? (
                      <CloseButton>
                        <Text
                          as="a"
                          color="primary-200"
                          aria-label={label}
                          title={label}
                          size="h5"
                          weight="bold"
                        >
                          {label}
                        </Text>
                      </CloseButton>
                    ) : subsections ? (
                      <SubMenu.Root>
                        <SubMenu.Trigger onClick={() => setIsSubMenuOpen(!isSubMenuOpen)}>
                          <Text
                            as="a"
                            color="accent-white"
                            aria-label={label}
                            title={label}
                            size="h5"
                            weight="bold"
                          >
                            {label}
                          </Text>
                          <IconWrapper>
                            <Icon
                              size="4"
                              name="arrow-down-line"
                              className={isSubMenuOpen ? 'rotate' : ''}
                            />
                          </IconWrapper>
                        </SubMenu.Trigger>
                        <SubMenu.Content>
                          {
                            <Stack
                              css={{ marginTop: '$2', marginLeft: '$2' }}
                              direction="column"
                              gap="2"
                            >
                              <Link href="/recipes" passHref>
                                <Text
                                  as="a"
                                  color="accent-white"
                                  title={t('header:recipes.overview')}
                                  size="h5"
                                  weight="bold"
                                >
                                  {t('header:recipes.overview')}
                                </Text>
                              </Link>
                              {RECIPES_NAV.map((recipe, index) => (
                                <Link key={index} href={recipe.url} passHref>
                                  <Text
                                    as="a"
                                    color="accent-white"
                                    title={recipe.label}
                                    size="h5"
                                    weight="bold"
                                  >
                                    {t(`${recipe.label}`)}
                                  </Text>
                                </Link>
                              ))}
                            </Stack>
                          }
                        </SubMenu.Content>
                      </SubMenu.Root>
                    ) : (
                      <Link href={href} passHref>
                        <Text
                          as="a"
                          color="accent-white"
                          aria-label={label}
                          title={label}
                          size="h5"
                          weight="bold"
                        >
                          {label}
                        </Text>
                      </Link>
                    )}
                  </li>
                ))}
              </Stack>
            </SectionsNav>

            <SocialNav>
              <Stack as="ul" justifyContent="space-between">
                {SOCIAL_NAV.map(({ icon, label, href }) => (
                  <li key={href}>
                    <a href={href} aria-label={label} target="_blank" rel="noreferrer">
                      <Icon name={icon} />
                    </a>
                  </li>
                ))}
              </Stack>
            </SocialNav>
          </Stack>
        </Stack>
      </Content>
    </Root>
  )
}

MobileHeaderMenu.displayName = 'MobileHeaderMenu'
