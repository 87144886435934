import { styled } from 'stitches.config'

export const OverlayRoot = styled('div', {
  background: '$secondary-100',
  height: '100%',
  width: 'calc(100% - $3 * 2)',
  position: 'absolute',
  opacity: 1,
  zIndex: 2,
  transition: 'opacity 0.7s ease-in-out, visibility 0.7s ease-in-out',
  visibility: 'visible',

  '@bp2': {
   width: 'calc(100% - $8 * 2)',
  },

  variants: {
    isLoading: {
      false: {
        opacity: 0,
        visibility: 'hidden',
      },
      true: {
        opacity: 1.0,
      },
    },
  },
})
