import { styled, keyframes } from 'stitches.config'
import * as RadixDialog from '@radix-ui/react-dialog'
import { buttonReset } from 'components/Button/Button.styles'
import { Collapsible } from 'components/Collapsible'

const slideIn = keyframes({
  from: { marginLeft: '-5vh', opacity: 0.7 },
  to: { marginLeft: '0vh', opacity: 1 },
})

export const Content = styled(RadixDialog.Content, {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  color: '$accent-white',
  backgroundColor: '$primary-500',
  backgroundImage: 'url(/assets/pattern-mobile-menu.png)',
  padding: '$2 $3 $4',

  '&[data-state=open]': {
    animation: `${slideIn} 300ms ease-out`,
  },
})

export const CloseButton = styled(RadixDialog.Close, {
  ...buttonReset,
  cursor: 'pointer',
  pointerEvents: 'auto',
})

export const SectionsNav = styled('nav', {
  paddingTop: '$8',
})

export const SocialNav = styled('nav', {
  px: '$4',
})

const SubMenuHeader = styled(Collapsible.Trigger, {
  padding: 0,
  border: 'none',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '$1',
})

export const SubMenu = {
  Root: Collapsible.Root,
  Trigger: SubMenuHeader,
  Content: Collapsible.Content,
}
