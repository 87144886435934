import { OverlayRoot } from "./Overlay.styles"

type OverlayProps = {
    isLoading: boolean
}

export const Overlay = (props: OverlayProps) => {

    const { isLoading } = props

    return (
        <OverlayRoot isLoading={isLoading}></OverlayRoot>
    )
}